export default {
  validarMenu(caja) {
    if(Number(caja.tipo_operacion) === 100 && caja.operacionCajaId !== "") {
      localStorage.setItem("bloqueoCaja", "false");
      localStorage.setItem("bloqueoCorte", "false");
      localStorage.setItem("bloqueoVentas", "true");
    } else if(Number(caja.tipo_operacion) === 101) {
      localStorage.setItem("bloqueoCaja", "true");
      localStorage.setItem("bloqueoCorte", "true");
      localStorage.setItem("bloqueoVentas", "false");
    } else if(Number(caja.tipo_operacion) === 102  && caja.operacionCajaId !== "") {
      localStorage.setItem("bloqueoCaja", "false");
      localStorage.setItem("bloqueoCorte", "false");
      localStorage.setItem("bloqueoVentas", "false");
    } else if (caja.operacionCajaId === "" && Number(caja.tipo_operacion) !== 101) {
      localStorage.setItem("bloqueoCaja", "true");
      localStorage.setItem("bloqueoVentas", "true");
      localStorage.setItem("bloqueoCorte", "false");
    }
  }
}