//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CajaServices from "../../Services/CajaServices";
import LoginHelper  from "../../Services/LoginHelper";

const cajaServices = new CajaServices();
export default {
  name: "corteCajaMostrador",
  components: {
    ValidarCredencialesModal: () => import("../componentes/validarCredencialesModal"),
  },
  data() {
    return {
      tienda: JSON.parse(localStorage.getItem("tienda")),
      operacionCajaObj: {ingresos: [], incrementos: []},
      cajaObj: {ventas: []},
      operacionCajaId: localStorage.getItem("operacionCajaId"),
      usuario: localStorage.getItem("usuario"),
      usuarioNombre: localStorage.getItem("nombre"),
      montoApertura: "",
      corteTab: "acumulados",
      columnasAcumulados: [
        {name: "index", label: "##", align: "center"},
        {label: 'Forma pago', align: 'left', field: 'nombreFormaPago', headerClasses: 'w55p'},
        {
          label: 'Total acumulado',
          align: 'right',
          field: 'montoTotal',
          headerClasses: 'w35p',
          format: val => this.accounting.formatMoney(val)
        },
      ],
      accion: "",
      columnasDetalle: [
        {label: 'Venta', align: 'left', field: 'ventaFolio', headerClasses: 'w15p'},
        {label: 'Forma pago', align: 'left', field: 'formaPago', headerClasses: 'w30p'},
        {
          label: 'Registro',
          align: 'left',
          field: 'fechaAplicacion',
          headerClasses: 'w35p',
          format: val => this.dateFormat(val, 2)
        },
        {label: 'Pago', align: 'right', field: 'monto', format: val => this.accounting.formatMoney(val)},
      ],
      formasPago: JSON.parse(localStorage.getItem("formasPago")),
      credenciales: {usuario: "", password: ""},
      validarCredencialesModal: false,
      totalOperacion: "",
      seccionValida: false,
      columnasIncremento: [
        {name: "index", label: "##", align: "center"},
        {label: 'Descripcion', align: 'left', field: 'descripcion', headerClasses: 'w55p'},
        {
          label: 'monto',
          align: 'right',
          field: 'montoIncremento',
          headerClasses: 'w35p',
          format: val => this.accounting.formatMoney(val)
        },
      ],
      monto: "",
      montoCaja: "",
      montoRetiro: "",
      montoTarjetaCredito: "",
      montoTarjetaDebito: "",
      montoCheque: "",
      montoTransferencia: "",
      montoOtros: "",
      fpDefault: ["01", "02", "03", "04", "28"],
      corteParcialModal: false,
      columnasCortesParciales: [
        {label: "# Corte", field: "numeroCorte", align: "center"},
        {label: 'Autorizó', align: 'left', field: 'autorRegistro', headerClasses: "w55p"},
        {label: 'Retirado', align: 'right', field: 'montoEfectivo', format: val => this.accounting.formatMoney(val)},
        {name:"opt", align: "center"}
      ],
      cierreCajaModal: false,
      resumenCaja: false,
      cierreCajaObj: {},
    }
  },
  computed: {
    existeOperacionCaja() {
      return this.operacionCajaId !== "null";
    },
    totalDiferencia() {
      return Number(this.monto) - Number(this.totalOperacion);
    },
    nuevoFondo() {
      return Number(this.montoCaja) - Number(this.montoRetiro);
    },
    sumarMontos() {
      return Number(this.montoRetiro || 0) +
             Number(this.montoTarjetaCredito || 0) +
             Number(this.montoTarjetaDebito || 0) +
             Number(this.montoCheque || 0) +
             Number(this.montoTransferencia || 0);
    },
    existenOtrosMontos() {
      return _.filter(this.formasPago, fp => !this.fpDefault.includes(fp.c_forma_pago)) > 0;
    },
  },
  async mounted() {
    this.loader(true);
    await this.cargarCorteCaja();
    if (!this.existeOperacionCaja)
      this.$nextTick(() => this.$refs.first.focus());

    this.loader(false);
  },
  methods: {
    sumBy: _.sumBy,
    filter: _.filter,
    isEmpty: _.isEmpty,
    cargarCorteCaja() {
      let filtros = {
        tiendaId: this.tienda.tiendaId,
        cajaId: localStorage.getItem("cajaId")
      };

      return cajaServices.cargarCorte(filtros)
          .then(resp => {
            let op = resp.operacionCaja || {ingresos: [], incrementos: []};

            this.cajaObj          = resp;
            this.operacionCajaObj = op;
            this.operacionCajaId  = resp.operacionCajaId || "null";
            localStorage.setItem("operacionCaja", JSON.stringify(resp.operacionCaja));
            localStorage.setItem("operacionCajaId", resp.operacionCajaId);
            LoginHelper.validarMenu(this.cajaObj);
            this.cajaObj.ventas = resp.ventas || [];
            this.totalOperacion = _.isEmpty(op) ? 0 : Number(op.ingresosTotal) + Number(op.montoApertura) -
                                                      Number(op.egresosTotal);
          })
          .catch(error => this.alertShow(error, "error"));
    },
    cargarAccion(tipo) {
      if (tipo < 6) this.seccionValida = false;
      switch (tipo) {
        case 1:
          this.accion = "iniciarOp";
          break;
        case 2:
          this.accion = "resumen";
          break;
        case 3:
          this.accion = "incremento";
          break;
        case 4:
          this.accion = "parcial";
          break;
        case 5:
          this.accion = "cierreCaja";
          break;
        case 6:
          this.accion = "guardarIncremento";
          break;
        case 7:
          this.accion = "corteParcial";
          break;
        case 8:
          this.accion = "guardarCierreCaja";
          break;
      }
      this.validarCredencialesModal = true;
    },
    cerrarCardAcciones() {
      this.accion              = "";
      this.monto               = "";
      this.montoApertura       = "";
      this.montoRetiro         = "";
      this.montoCaja           = "";
      this.montoTarjetaCredito = "";
      this.montoTarjetaDebito  = "";
      this.montoCheque         = "";
      this.montoTransferencia  = "";
      this.montoOtros          = "";
      this.seccionValida       = false;
    },
    cerrarAutorizacion() {
      this.credenciales             = {usuario: "", password: ""};
      this.validarCredencialesModal = false;
    },
    validarAutorizacion(credenciales) {
      this.credenciales = credenciales;
      switch (this.accion) {
        case "iniciarOp":
          return this.iniciarOperacionCaja();
        case "resumen":
          return this.validarPermisos(this.permisos.PERMISO_CORTE_CONSULTAR_OPERACION);
        case "incremento":
          return this.validarPermisos(this.permisos.PERMISO_CORTE_INCREMENTO_FONDO);
        case "parcial":
          return this.validarPermisos(this.permisos.PERMISO_CORTE_RETIRO_PARCIAL);
        case "cierreCaja":
          return this.validarPermisos(this.permisos.PERMISO_CORTE_CIERRE_CAJA);
        case "guardarIncremento":
          return this.registrarIncrementoFondo();
        case "corteParcial":
          return this.generarCorteCaja();
        case "guardarCierreCaja":
          return this.cerrarOperacionCaja();
      }
    },
    iniciarOperacionCaja() {
      this.loader(true);
      let data = {
        monto: this.montoApertura,
        cajaId: this.cajaObj.caja_id,
        usuario: this.credenciales.usuario,
        password: this.credenciales.password,
        statusOperacion: 200,
      };
      return cajaServices.iniciarOperacionCaja(data)
          .then(resp => {
            localStorage.setItem("operacionCajaId", resp.data);
            this.operacionCajaId = resp.data;
            this.cerrarAutorizacion();
            this.cargarCorteCaja();
            this.alertShow(resp.mensaje, "exito");
          })
          .catch(error => this.alertShow(error, "error"))
          .then(() => this.loader(false));
    },
    generarCorteCaja() {
      this.loader(true);
      let data = {
        tienda: this.tienda.tiendaId,
        caja: this.cajaObj.caja_id,
        cajaOperacion: this.operacionCajaId,
        montoRetiro: this.montoRetiro,
        montoCaja: this.montoCaja,
        montoTarjetaCredito: this.montoTarjetaCredito || 0,
        montoTarjetaDebito: this.montoTarjetaDebito || 0,
        montoCheque: this.montoCheque || 0,
        montoTransferencia: this.montoTransferencia || 0,
        montoOtros: this.montoOtros || 0,
        fondo: this.nuevoFondo,
        usuario: this.credenciales.usuario,
        password: this.credenciales.password,
      };

      return cajaServices.realizarCorteParcial(data)
          .then(resp => {
            this.alertShow(resp.mensaje, "exito");
            this.loader(false);
            this.cerrarAutorizacion();
            this.cerrarCardAcciones();
            this.cargarCorteCaja();
            this.corteParcialModal = false;
          }).catch(error => {
            this.loader(false);
            this.alertShow(error, "error");
          });
    },
    cerrarOperacionCaja() {
      this.loader(true);
      let data = {
        cajaOperacionId: this.operacionCajaId,
        montoEfectivo: this.montoRetiro,
        montoTarjetaCredito: this.montoTarjetaCredito || 0,
        montoTarjetaDebito: this.montoTarjetaDebito || 0,
        montoCheque: this.montoCheque || 0,
        montoTransferencia: this.montoTransferencia || 0,
        montoOtros: this.montoOtros || 0,
        montoCierre: this.sumarMontos,
        usuario: this.credenciales.usuario,
        password: this.credenciales.password,
      };

      return cajaServices.cerrarOperacionCaja(data)
          .then(resp => {
            this.alertShow(resp.mensaje, "exito");
            this.cierreCajaObj   = resp.data;
            this.cierreCajaModal = false;
            this.resumenCaja     = true;
            this.loader(false);
          }).catch(error => {
            this.loader(false);
            this.alertShow(error, "error");
          });
    },
    limpiarCorteCaja() {
      localStorage.setItem("operacionCajaId", "null");
      this.operacionCajaObj = {ingresos: [], incrementos: []};
      this.operacionCajaId  = "null";
      this.resumenCaja      = false;
      this.cierreCajaObj    = {};
      this.cerrarAutorizacion();
      this.cerrarCardAcciones();
      this.$nextTick(() => this.$refs.first.focus());
      return this.cargarCorteCaja();
    },
    validarPermisos(permiso) {
      let filtros = {
        permiso,
        usuario: this.credenciales.usuario,
        password: this.credenciales.password
      };

      return cajaServices.validarPermisoCredenciales(filtros)
          .then(() => {
            this.seccionValida = true;
            this.cerrarAutorizacion();
            if (this.accion !== "resumen") this.$nextTick(() => this.$refs.first.focus());
          })
          .catch(error => this.alertShow(error, "error"));
    },
    registrarIncrementoFondo() {
      this.loader(true);
      let data = {
        cajaOperacionId: this.operacionCajaId,
        monto: this.monto,
        usuario: this.credenciales.usuario,
        password: this.credenciales.password,
      };

      return cajaServices.registrarIncrementoFondo(data)
          .then(resp => {
            this.alertShow(resp.mensaje, "exito");
            this.monto = "";
            this.cerrarAutorizacion();
            this.cargarCorteCaja();
            this.loader(false);
            this.$nextTick(() => this.$refs.first.focus());
          }).catch(error => {
            this.loader(false);
            this.alertShow(error, "error");
          });
    },
    validarCorteParcial() {
      let op = this.operacionCajaObj;
      let cajaEfectivo = _.find(op.ingresos, {claveFormaPago: "01"}),
          cajaCheque = _.find(op.ingresos, {claveFormaPago: "02"}),
          cajaTransf = _.find(op.ingresos, {claveFormaPago: "03"}),
          cajaDebito = _.find(op.ingresos, {claveFormaPago: "28"}),
          cajaCredito = _.find(op.ingresos, {claveFormaPago: "04"});

      if (Number(this.montoRetiro) > Number(this.montoCaja) ||
          (!_.isEmpty(cajaEfectivo) && Number(this.montoRetiro) > (Number(cajaEfectivo.montoTotal) + Number(op.montoApertura))) ||
          (!_.isEmpty(cajaCheque) && Number(this.montoCheque) > Number(cajaCheque.montoTotal)) ||
          (!_.isEmpty(cajaTransf) && Number(this.montoTransferencia) > Number(cajaTransf.montoTotal)) ||
          (!_.isEmpty(cajaDebito) && Number(this.montoTarjetaDebito) > Number(cajaDebito.montoTotal)) ||
          (!_.isEmpty(cajaCredito) && Number(this.montoTarjetaCredito) > Number(cajaCredito.montoTotal))
      )
        return this.alertShow("El monto de retiro no puede ser mayor a lo registrado en caja. Valide las cantidades ingresadas", "error");
      this.corteParcialModal = true;
    },
    imprimirTicket() {
      this.loader(true);

      return cajaServices.reimprimirTicket({corteCajaId: this.operacionCajaId})
          .then(resp => {
            this.alertShow(resp.mensaje, "exito");
            this.loader(false);
          }).catch(error => {
            this.loader(false);
            this.alertShow(error, "error");
          });
    },
    imprimirTicketParcial(retiroParcialId) {
      this.loader(true);

      return cajaServices.reimprimirTicketParcial({retiroParcialId})
          .then(resp => {
            this.alertShow(resp.mensaje, "exito");
            this.loader(false);
          }).catch(error => {
            this.loader(false);
            this.alertShow(error, "error");
          });
    },
  }
}
