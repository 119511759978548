import RequestAxios from "../Repositories/RequestAxios";
import axiosUtils    from "./Requests/AxiosUtils";
import VentaServices from "./VentaServices";

const ventaServices = new VentaServices();

export default class CajaServices {

  obtenerDatosCajaMostradorInicial(filtros) {
    let url       = "cajaMostrador/cargaInicial",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  getPdfVenta(filtros) {
    return ventaServices.obtenerPdf(filtros);
  }

  buscarVentas(filtros) {
    let url       = "cajaMostrador/obtenerVentas",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }
  async registrarPagos(params) {
    let url       = "cajaMostrador/registrarPagos",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }

  realizarCorteParcial(params) {
      let url       = "cajaMostrador/realizarCorteParcial",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }

  cargarCorte(filtros) {
    let url       = "cajaMostrador/cargarCorteCaja",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  cerrarOperacionCaja(params) {
    let url       = "cajaMostrador/cerrarOperacionCaja",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }

  iniciarOperacionCaja(params) {
    let url       = "cajaMostrador/iniciarOperacionCaja",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }

  validarPermisoCredenciales(filtros) {
    return ventaServices.validarPermisoCredenciales(filtros);
  }

  registrarIncrementoFondo(params) {
    let url       = "cajaMostrador/incrementarFondo",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }

    reimprimirTicket(params) {
      let url       = "operacion_caja/imprimirCorteCaja",
          timestamp = new Date().getTime(),
          hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

      let data = {...params, timestamp, hash: hash.substr(0, 12)};
      return RequestAxios.requestPOST(url, data);
    }

  reimprimirTicketParcial(params) {
    let url       = "cajaMostrador/imprimirTicketParcial",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }
}